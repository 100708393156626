import styles from './OrderItem.module.css';

const OrderItem = props => {

	let itemName = '';
	if (props.item.itemName === 'premium') itemName = 'Premium';
	if (props.item.itemName === 'luxe') itemName = 'Luxe';
	if (props.item.itemName === 'privateReserve') itemName = 'Private Reserve';
	if (props.item.itemName === 'premium750') itemName = 'Premium - 750ml';
	if (props.item.itemName === 'luxe750') itemName = 'Luxe - 750ml';
	if (props.item.itemName === 'privateReserve750') itemName = 'Private Reserve - 750ml';
	if (props.item.itemName === 'tastingCollection') itemName = 'Tasting Collection';
	if (props.item.itemName === 'holidayTastingCollection') itemName = 'Holiday Tasting Collection';
	if (props.item.itemName === 'everydayLuxury') itemName = 'Everyday Luxury';
	if (props.item.itemName === 'refinedPalate') itemName = 'Refined Palate';
	if (props.item.itemName === 'trueConnoisseur') itemName = 'True Connoisseur';


	let packageType = 'bottle'; 
	if (
		props.item.itemName === 'tastingCollection' || 
		props.item.itemName === 'holidayTastingCollection' || 
		props.item.itemName === 'refinedPalate' || 
		props.item.itemName === 'trueConnoisseur'
	) { packageType = 'set' }

	let isRecurringCopy = '';	
	if (
		props.item.itemName === 'everydayLuxury' || 
		props.item.itemName === 'refinedPalate' || 
		props.item.itemName === 'trueConnoisseur'
	) { isRecurringCopy = '/m' }

	let listPriceStriked = null;
	if (props.item.itemPrice !== props.item.itemListPrice){
		listPriceStriked = (
			<p className={styles.StrikedPrice}>
				<small>{`$${props.item.itemListPrice}/${packageType}${isRecurringCopy}`}</small>
			</p>
		)
	} 

	return (
		<div className='d-flex justify-content-between mb-3'>
			<div className={styles.ItemDescription}>
				<p className='lead mb-0'>{itemName}</p>
				<div className='d-flex align-items-center'>
					{listPriceStriked}
					<p className='mb-0'><small>{`${props.item.itemPrice}/${packageType}`}</small></p>
				</div>
			</div>
			<p className='lead mb-0 flex-grow-1'>{`${props.item.quantity} ${packageType}`}</p>
			<p className='lead mb-0'>{`$${props.item.total}${isRecurringCopy}`}</p>
		</div>
	)

}
export default OrderItem